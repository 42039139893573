.slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.slick-dots li {
  margin: 0 0.15vw;
  /* margin: 50px; */
}

.slick-dots li button {
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 10px;
  outline: none;
  transition: background-color 0.3s;
  width: 10px;
}

.slick-dots li.slick-active button {
  background-color: #ffc300;
}
